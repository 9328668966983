<template>
  <div class="five wide column">
    <div @click="openPaper()" style="background: #ffffff; height: 100%; width: 100%; cursor: pointer;">
      <img v-if="item.enclosure" :src="item.enclosure.url">
      <div v-if="item.enclosure">
        <h2>{{ item.title }}</h2>
        <span v-if="item.link.startsWith('https://www.srf.ch/')" class="ui red label">SRF</span>
        <span v-else-if="item.link.startsWith('https://www.landbote.ch/')" class="ui teal label">Landbote</span>
        <span v-else-if="item.link.startsWith('https://www.20min.ch/')" class="ui blue label">20min</span>
        <span v-else-if="item.link.startsWith('https://www.tagesanzeiger.ch/')" class="ui black label">Tagesanzeiger</span>
        <span v-else-if="item.link.startsWith('https://interaktiv.tagesanzeiger.ch/')" class="ui black label">Tagesanzeiger</span>
        <span v-else-if="item.link.startsWith('https://www.nzz.ch/')" class="ui grey label">NZZ</span>
        <span v-else-if="item.link.startsWith('https://www.blick.ch/')" class="ui basic red label">Blick</span>
        <span v-else-if="item.link.startsWith('https://marchanzeiger.ch/')" class="ui basic blue label">March Anzeiger</span>
        <span v-else-if="item.link.startsWith('https://www.luzernerzeitung.ch/')" class="ui basic blue label">Luzerner Zeitung</span>
        <span v-else-if="item.link.startsWith('https://www.suedostschweiz.ch/')" class="ui basic green label">südostschweiz</span>
        <span v-else-if="item.link.startsWith('https://march24.ch/')" class="ui yellow label">March24</span>
        <span v-else class="ui basic label">Unknown</span>
        <p>{{ item.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewspaperItem',
  props: ['item'],
  methods: {
    openPaper() {
      window.open(this.item.link, '_blank')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  font-size: 17px !important;
  margin-bottom: 10px !important;
}
div.column > div > img {
  width: 100%;
  height: 18.5vw;
}
div.column > div > div {
  width: 100%;
  padding: 10px;
}
div.column > div > div > p {
  margin-top: 10px !important;
}
@media only screen and (max-width: 767px) {
  div.column > div {
    max-height: 30vw;
    overflow: hidden;
  }
  div.column > div > img {
    width: 50%;
    height: 30vw;
    float: left;
  }
  div.column > div > div {
    display: inline-block;
    width: 50%;
  }
}
@media only screen and (max-width: 545px) {
  div.column > div > div > p {
    display: none;
  }
  .ui.label {
    display: none;
  }
}
</style>
