<template>
  <div class="ui centered stackable grid" style="margin: 10px;">
    <newspaper-item v-for="(item, index) in items" :key="index" :item="item"></newspaper-item>
  </div>
</template>

<script>
import feed from '../lib/feed/index.js'
import NewspaperItem from '../components/NewspaperItem.vue'

export default {
  name: 'News',
  components: {
    'newspaper-item': NewspaperItem
  },
  data () {
    return {
      items: []
    }
  },
  mounted () {
    feed(this.$route.params.feed, news => this.items = (news[parseInt(this.$route.params.id)] || {}).items || [])
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name == 'News') {
      feed(to.params.feed, news => this.items = (news[parseInt(this.$route.params.id)] || {}).items || [])
    }
    next()
  }
}
</script>
