<template>
  <div style="height: 80px; padding: 20px; background: #ffffff; text-align: center;">
    <img src="/logo-full.png" style="height: 100%; display: inline-block;">
  </div>
  <div class="ui secondary pointing menu" style="background: #ffffff; margin-top: 0;">
    <router-link to="/headlines" class="item">Front</router-link>
    <router-link to="/world" class="item">International</router-link>
    <router-link to="/country-ch" class="item">Schweiz</router-link>
    <router-link to="/region" class="item">Region</router-link>
  </div>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>
