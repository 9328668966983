import axios from 'axios'

export default function (category, callback) {

  if (! window.herald) {
    window.herald = {
      cache: {}
    }
  }

  if (window.herald.cache[category]) {
    callback(window.herald.cache[category])
    return
  }

  axios.get('/api/feeds/' + category, { withCredentials: true }).then(res => {
    var news = res.data.news
    for (let i = 0, len = news.length; i < len; i += 1) {
      news[i].items = news[i].items.sort((a, b) => (new Date(b.isoDate) - (new Date(a.isoDate))))

      for (let j = 0, lenj = news[i].items.length; j < lenj; j += 1) {
        if (! news[i].items[j].enclosure) {
          const img = (news[i].items[j].content.match(/<img[^>]+src=.([^"']+)/) || [])[1]
          if (img)
            news[i].items[j].enclosure = {
              type: 'image',
              url: img
            }
        }
        news[i].items[j].content = news[i].items[j].content.replace(/<[^>]*>/g, '')
      }

      const srf = news[i].items.filter(e => e.link.startsWith('https://www.srf.ch/'))[0]
      if (srf) {
        news[i].title = srf.title
        news[i].content = srf.content
        news[i].image = (srf.enclosure || {}).url
      } else {
        news[i].title = news[i].items[0].title
        news[i].content = news[i].items[0].content
        news[i].image = (news[i].items[0].enclosure || {}).url
      }

      if (! news[i].image) {
        news[i].image = news[i].images.filter(e => e.startsWith('https://cdn.unitycms.io/'))[0] || news[i].images[0]
      }

      news[i].content = news[i].content.replace(/<.*>/g, '')
    }

    news = news.filter(e => typeof e.image !== 'undefined')

    window.herald.cache[category] = news
    callback(news)
  })
}
