<template>
  <div class="five wide column">
    <div @click="openGroup()" style="background: #ffffff; height: 100%; width: 100%; cursor: pointer;">
      <img :src="item.image">
      <div>
        <h2>{{ item.title }}</h2>
        <span class="ui basic label">#{{ item.labels[0] }}</span>
        <span class="ui basic label">#{{ item.labels[1] }}</span>
        <span class="ui basic label">#{{ item.labels[2] }}</span>
        <p>{{ item.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsItem',
  props: ['item', 'index'],
  methods: {
    openGroup() {
      this.$router.push({ path: '/' + this.$route.params.feed + '/' + this.index})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  font-size: 17px !important;
  margin-bottom: 10px !important;
}
div.column > div > img {
  width: 100%;
  height: 18.5vw;
}
div.column > div > div {
  width: 100%;
  padding: 10px;
}
div.column > div > div > p {
  margin-top: 10px !important;
}
@media only screen and (max-width: 767px) {
  div.column > div {
    max-height: 30vw;
    overflow: hidden;
  }
  div.column > div > img {
    width: 50%;
    height: 30vw;
    float: left;
  }
  div.column > div > div {
    display: inline-block;
    width: 50%;
  }
}
@media only screen and (max-width: 545px) {
  div.column > div > div > p {
    display: none;
  }
  .ui.label {
    display: none;
  }
}
</style>
