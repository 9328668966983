<template>
  <div class="ui centered stackable grid" style="margin: 10px;">
    <news-item v-for="(item, index) in news" :key="index" :index="index" :item="item"></news-item>
  </div>
</template>

<script>
import feed from '../lib/feed/index.js'
import NewsItem from '../components/NewsItem.vue'

export default {
  name: 'Feed',
  components: {
    'news-item': NewsItem
  },
  data () {
    return {
      news: []
    }
  },
  mounted () {
    feed(this.$route.params.feed, news => this.news = news)
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name == 'Feed') {
      feed(to.params.feed, news => this.news = news)
    }
    next()
  }
}
</script>
